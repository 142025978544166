import React from "react";
import styles from "./CollectionStatus.module.css"
import {MyWeb3} from "../../App";
import {useTranslation} from "react-i18next";

interface Props {
    w3: MyWeb3
    isOwner: boolean
}

export default function CollectionStatus({ w3, isOwner }: Props): JSX.Element {
    const [t] = useTranslation()
    const data = w3.contractData

    function getUserType() {
        if (isOwner) return t('owner')
        let type = t('public')
        if (data.isWhiteListed) {
            type = t('whiteListed')
        } else if (data.isAllowListed) {
            type = t('allowListed')
        }
        return type
    }

    return (
        <section className={styles.section}>
            <li className={styles.li}>
                <p className={styles.liTitle}>{t('walletAddress').toString()}</p>
                <p className={styles.liValue}>{ w3.account }</p>
            </li>
            <li className={`${styles.li} ${styles.multiCol}`}>
                <div className={styles.col}>
                    <p className={styles.liTitle}>{t('dropN').toString()}</p>
                    <p className={styles.liValue}>{data.dropCount}</p>
                </div>
                <div className={`${styles.col} ${styles.colCenter}`}>
                    <p className={styles.liTitle}>{t('supply').toString()}</p>
                    <p className={styles.liValue}>{ `${data.totalSupply} / ${data.currentMaxSupply}` }</p>
                </div>
                <div className={styles.col}>
                    <p className={styles.liTitle}>{t('yourMint').toString()}</p>
                    <p className={styles.liValue}>{ `${isOwner ? data.addressMintedBalance : `${data.addressMintedBalance} / ${data.maxMintPerAddress}` }`}</p>
                </div>
            </li>
            <li className={`${styles.li} ${styles.multiCol}`}>
                <div className={styles.col}>
                    <p className={styles.liTitle}>{t('userType').toString()}</p>
                    <p className={styles.liValue}>{getUserType()}</p>
                </div>
                <div className={`${styles.col} ${styles.colCenter}`}>
                    <p className={styles.liTitle}>Allow Public</p>
                    <p className={styles.liValue}>{data.allowPublic ? t('yes').toString() : t('no').toString()}</p>
                </div>
                <div className={styles.col}>
                    <p className={styles.liTitle}>Contract Paused</p>
                    <p className={styles.liValue}>{data.paused ? t('yes').toString() : t('no').toString()}</p>
                </div>
            </li>
        </section>
    );
}
