import React, {useCallback, useEffect, useState} from 'react';
import styles from "./App.module.css";
import {BigNumber, Contract, ethers} from "ethers"
import {useTranslation} from "react-i18next";
import { ExternalProvider, Web3Provider } from '@ethersproject/providers';
import detectEthereumProvider from "@metamask/detect-provider";
import Homepage from "./components/Homepage/Homepage";
import MinterPage from "./components/MinterPage/MinterPage";
import logo from "../src/images/CryptoMADONNE-Logo-1024x316.png"
const abi = require('../../smart-contract/artifacts/contracts/CryptoMadonne.sol/CryptoMadonne.json').abi

const contractAddress = '0x3f241feb5c1C008f87aB066492E15dBF8E6CDF94'

interface ContractData {
  owner: string
  totalSupply: number
  currentMaxSupply: number
  dropCount: number
  addressMintedBalance: number
  maxMintPerAddress: number
  maxMintAmount: number
  paused: boolean
  allowPublic: boolean
  isWhiteListed: boolean
  isAllowListed: boolean
  cost: BigNumber
}

export interface MyWeb3 {
  account: string
  provider: Web3Provider
  contract: Contract
  contractData: ContractData
}

export default function App(): JSX.Element {
  const [t] = useTranslation()

  const [connectInProgress, setConnectInProgress] = useState(false)
  const [errorText, _setErrorText] = useState('')
  const [infoText, _setInfoText] = useState('')

  const [w3, setW3] = useState<null | MyWeb3>(null)

  function setErrorText(value: string) {
    _setErrorText(value)
  }

  function setInfoText(value: string) {
    _setInfoText(value)
  }

  const onClickConnectWallet = useCallback(() => {

    function registerWalletEvents(browserProvider: ExternalProvider) {
      // @ts-ignore
      browserProvider.on('accountsChanged', (data) => {
        console.log('accountsChanged', data)
        if (!data?.length) {
          window.location.reload();
          return;
        }
        onClickConnectWallet()
      });

      // @ts-ignore
      browserProvider.on('chainChanged', (data) => {
        console.log('chainChanged', data)
        window.location.reload();
      });
    }

    async function connectWallet() {
      const browserProvider = await detectEthereumProvider() as ExternalProvider;

      if (browserProvider?.isMetaMask !== true) {
        throw Error(t('errMetamaskNotFound'))
      }
      registerWalletEvents(browserProvider)

      const provider = new ethers.providers.Web3Provider(browserProvider)

      // MetaMask requires requesting permission to connect users accounts
      let accounts
      try {
        accounts = await provider.send("eth_requestAccounts", [])
      } catch (err: any) {
        let errorText = err.message
        switch (err.code) {
          case -32002: errorText = t('errEthRequestAccountsInProgress'); break
        }
        throw Error(errorText)
      }

      if (!accounts.length) throw Error(t('errMetamaskAccountsNotFound'))
      const account = accounts[0]

      const network = await provider.getNetwork();
      // let networkConfig: NetworkConfigInterface;

      console.log('network', network)
      console.log('Connected to Network: ', network.name)

      // network.chainId 1: homestead
      // network.chainId 4: rinkeby
      // network.chainId 5: goerli

      if (network.chainId !== 1 && network.chainId !== 4 && network.chainId !== 5) {
        throw Error(t('unsupportedNetwork'))
      }

      if (await provider.getCode(contractAddress) === '0x') {
        throw Error(t('errContractNotFound'))
      }

      const contract = new ethers.Contract(contractAddress, abi, provider.getSigner())

      const contractData: ContractData = {
        owner: await contract.owner(),
        totalSupply: (await contract.totalSupply()).toNumber(),
        currentMaxSupply: (await contract.currentMaxSupply()).toNumber(),
        dropCount: (await contract.dropCount()).toNumber(),
        addressMintedBalance: (await contract.addressMintedBalance(account)).toNumber(),
        maxMintPerAddress: (await contract.maxMintPerAddress()).toNumber(),
        maxMintAmount: (await contract.maxMintAmount()).toNumber(),
        paused: await contract.paused(),
        allowPublic: await contract.allowPublic(),
        isWhiteListed: await contract.isWhiteListed(account),
        isAllowListed: await contract.isAllowListed(account),
        cost: await contract.getCostPerAddress(account),
      }
      setW3({ account, contract, provider, contractData })
    }

    setConnectInProgress(true)
    setInfoText(t('connectionInProgress'))
    connectWallet()
        .then(() => {
          setErrorText('')
        })
        .catch((err) => {
          console.error(err)
          setErrorText(err.message)
        })
        .finally(() => {
          setConnectInProgress(false)
          setInfoText('')
        })
  }, [t])

  // useEffect(() => {
  //   onClickConnectWallet()
  // }, [onClickConnectWallet])

  function mintDone() {
    onClickConnectWallet()
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <img className={styles.logo} src={logo} alt="logo"/>
      </header>

      {errorText && (
          <div className={styles.error}>
            <p className={styles.errorTitle}>{t('error').toString()}</p>
            <p className={styles.errorDescription}>{errorText}</p>
            <button onClick={() => setErrorText('')} className={`${styles.btn} ${styles.btnError}`}>{t('close').toString()}</button>
          </div>
      )}

      {infoText && (
          <div className={styles.info}>
            <p>{infoText}</p>
          </div>
      )}

      <main className={styles.main}>
        { w3
            ? <MinterPage w3={w3} mintDone={mintDone} setErrorText={setErrorText} setInfoText={setInfoText} />
            : <Homepage connectInProgress={connectInProgress} onClickConnectWallet={onClickConnectWallet} />
        }
      </main>
      {/*/!*<button onClick={initConnection}>Connect</button>*!/*/}
      {/*<button onClick={balance}>Get Balance</button>*/}
      {/*<button onClick={totalSupply}>totalSupply</button>*/}
      {/*<button onClick={currentMaxSupply}>currentMaxSupply</button>*/}
      {/*<button onClick={finalMaxSupply}>finalMaxSupply</button>*/}
      {/*/!*<button onClick={isWhiteListed}>isWhiteListed</button>*!/*/}
      {/*/!*<button onClick={isAllowListed}>isAllowListed</button>*!/*/}
      {/*/!*<button onClick={maxMintPerAddress}>maxMintPerAddress</button>*!/*/}
      {/*<button onClick={maxMintAmount}>maxMintAmount</button>*/}
      {/*/!*<button onClick={mint}>mint</button>*!/*/}
      {/*/!*<p>{ account }</p>*!/*/}
      {/*<button onClick={getCostPerAddress}>getCostPerAddress</button>*/}
    </div>
  )
}
