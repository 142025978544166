import React from "react";
import styles from "../../App.module.css";
import {useTranslation} from "react-i18next";

interface Props {
    connectInProgress: boolean
    onClickConnectWallet: () => void
}

export default function Homepage({ onClickConnectWallet, connectInProgress }: Props): JSX.Element {
    const [t] = useTranslation()
    return <button disabled={connectInProgress} className={`${styles.btn} ${styles.btnConnect}`} onClick={onClickConnectWallet}>{t('connectWallet').toString()}</button>
}
