import React, {useState} from "react";
import CollectionStatus from "../CollectionStatus/CollectionStatus";
import madonna from "../../../src/images/banda-semitrasparente-2048x956.png"
import {MyWeb3} from "../../App";
import styles from "./MinterPage.module.css"
import {BigNumber, utils} from "ethers";
import {useTranslation} from "react-i18next";

interface Props {
    w3: MyWeb3
    mintDone: () => void
    setErrorText: (value: string) => void
    setInfoText: (value: string) => void
}

export default function MinterPage({ w3, mintDone, setErrorText, setInfoText }: Props): JSX.Element {
    const [t] = useTranslation()
    const [mintAmount, setMintAmount] = useState(1)
    const [mintInProgress, setMintInProgress] = useState(false)

    const data = w3.contractData
    const isOwner = data.owner.toLowerCase() === w3.account.toLowerCase()
    const maxMintable = data.maxMintPerAddress - data.addressMintedBalance
    const isPublic = !isOwner && !data.isWhiteListed && !data.isAllowListed
    const totCost = data.cost.mul(mintAmount)
    const supplyRemaining = data.currentMaxSupply - data.totalSupply

    const isDisabledMoreMintBtn = isOwner ? mintAmount >= data.maxMintAmount : mintAmount >= maxMintable

    function lessMintNumber() {
        setMintAmount(mintAmount - 1)
    }

    function moreMintNumber() {
        setMintAmount(mintAmount + 1)
    }

    async function mint(mintAmount: number, totCost: BigNumber) {
        try {
            setMintInProgress(true)
            setInfoText(t('mintInProgress'))
            const tx = await w3?.contract.mint(mintAmount, { value: totCost })
            // wait until the transaction is mined
            await tx.wait()
            setInfoText('')
            mintDone()
            setMintAmount(1)
            setMintInProgress(false)
        } catch (err: any) {
            console.error(err)
            setErrorText(err.message)
            setInfoText('')
            setMintInProgress(false)
        }
    }

    return (
        <>
            <CollectionStatus isOwner={isOwner} w3={w3} />
            <article className={styles.article}>
                <header>
                    <img className={styles.imgMadonna} src={madonna} alt="madonna" />
                </header>
                <main className={styles.main}>
                    <span>{t('totPrice').toString()}</span>: <span className={styles.cost}>{`${utils.formatEther(totCost)} ETH`}</span>
                </main>
                <footer className={styles.footer}>
                    <div className={styles.btnsContainer}>
                        <button onClick={lessMintNumber} disabled={mintAmount <= 1} className={styles.btn}>-</button>
                        <div className={styles.mintAmount}>{ mintAmount }</div>
                        <button
                            onClick={moreMintNumber}
                            disabled={mintAmount >= supplyRemaining || isDisabledMoreMintBtn}
                            className={styles.btn}
                        >
                            +
                        </button>
                    </div>
                    <button
                        onClick={() => mint(mintAmount, totCost)}
                        disabled={mintInProgress || data.paused || (isPublic && !data.allowPublic)}
                        className={styles.mintBtn}
                    >
                        {t('mint').toString()}
                    </button>
                </footer>
            </article>
        </>
    )
}
